<template>
  <div class="pledge">
    <div class="pledge-header">
      <div class="pledge-title">{{ $t('Pledge.zhiya') }}</div>
      <div class="pledge-record" @click="tolink('PledgeRecord')">{{ $t('Pledge.zhiyajilu') }}</div>
    </div>

    <div class="form">
      <div class="form-label">{{ $t('Pledge.xuanzezhouqi') }}</div>
      <div class="input-wrap" @click="show = true">
        <q-input borderless v-model="days.text" :placeholder="$t('Pledge.qingxuanzezhouqi')" readonly />
      </div>
      <div class="form-label q-mt-md">{{ $t('Pledge.zhiyashuliang') }}</div>
      <div class="input-wrap">
        <q-input borderless v-model="num" :placeholder="$t('Pledge.qingshurubeishu')" />
      </div>
      <div class="form-label q-mt-md">{{ $t('Pledge.qingshurubeishu') }}</div>
      <div>
        <q-radio v-model="payType" val="2" label="USDT" class="q-mr-md" />
        <q-radio v-model="payType" val="3" label="USDA" />
      </div>

      <div class="btn" @click="submit">{{ $t('Pledge.querenzhiya') }}</div>
    </div>

    <!-- 选择币种 -->
    <van-popup v-model:show="show" round position="bottom">
      <van-picker
        :columns="daysList"
        @cancel="show = false"
        @confirm="onConfirm"
      />
    </van-popup>
  </div>
</template>

<script>
import { ref } from 'vue'
import { Picker, Popup } from 'vant';
import Web3Helper from "@/assets/js/web3helper.js";

export default {
  components: {
    [Picker.name]: Picker,
    [Popup.name]: Popup,
  },
  setup() {
    return {
      us: ref(null),
      num: ref(""),
      show: ref(false),
      days: ref(""),
      daysList: ref([]),
      payType: ref("2")
    }
  },
  mounted() {
    this.us = JSON.parse(this.$utils.getloc("us"))
    this.getList()
  },
  methods: {
    tolink(path) {
      this.$router.push(path)
    },
    onConfirm(e) {
      this.days = e
      this.show = false
    },
    getList() {
      let _this = this
      _this.$request.post(
        "api/Impawn/List",
        {
          token: _this.$utils.getloc("token"),
          userid: _this.us.userid,
        },
        (res) => {
          if(res.data.code == 100) {
            let data = res.data.data
            data.forEach(item => {
              item.text = item.name
              item.value = item.id
            })
            _this.daysList = data
          }
        }
      )
    },
    submit() {
      let _this = this
      console.log(_this.payType)
      if(this.days == '') {
        this.$q.dialog({
					message: _this.$t('Pledge.qingxuanzezhouqi'),
				});
        return
      }
      if(this.num == '') {
        this.$q.dialog({
					message: _this.$t('Pledge.qingshurushuliang'),
				});
        return
      }
      // if(this.num % 100 != 0) {
      //   this.$q.dialog({
			// 		message: "请输入100或100的倍数",
			// 	});
      //   return
      // }
      this.$q.loading.show({
        message: _this.$t('Pledge.zhiyazhong')
      })
      let web3Helper = new Web3Helper();
      web3Helper.getSign().then((signStr) => {
        _this.$request.post(
          "api/ImpawnUser/Impawn",
          {
            token: _this.$utils.getloc("token"),
            userid: _this.us.userid,
            pid: _this.days.id,
            jine: _this.num,
            cid: _this.payType,
            signStr
          },
          (res) => {
            _this.$q.loading.hide();
            _this.$q.dialog({
              message: res.data.msg,
            });
          }
        )
      })
    }
  }
}
</script>

<style scoped>
.pledge {
  padding: 15px 15px 0;
}

.pledge-header {
  margin-top: 5px;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.form {
  margin-top: 15px;
  padding: 15px;
  border-radius: 6px;
  background-color: #fff;
}

.pledge-title {
  font-weight: bold;
  font-size: 16px;
}

.pledge-record {
  color: gray;
  text-decoration: underline;
}

.form-label {
  margin-bottom: 6px;
}

.input-wrap {
  padding: 0 16px;
  border-radius: 10px;
  background-color: #f1f1f1;
}

.btn {
  margin: 15px 0 10px;
  padding: 0 10px;
  min-height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 99rem;
  color: #fff;
  background-color: #2C8B9F;
  font-size: 13px;
  word-break: break-all;
  text-align: center;
}
</style>